import { difference, isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components/macro';

import { DBId } from '@common/types/DBId';
import { useIsEditAvailable } from '@features/content/courses';
import { LanguageV2 } from '@features/content/languages';
import { Text } from '@features/theme';
import {
  MatchLexicalItemCreatorOption,
  MatchLexicalItemCreator,
  LexicalItemMatch,
  LEXICAL_ITEM_TMP_ID,
} from '@features/content/vocabularyReview';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { useAppSelector } from '@redux/store';
import { selectIsIssuesShown } from '@selectors/UiSelectors';

import { mapLexicalItemsMatchedToValues } from './_util/mapLexicalItemsMatchedToValues';
import { LexicalItemsMatchedWrapper, StyledValidationErrorDisplayer } from './styles';
import { useLexicalItemsMatched } from './useLexicalItemsMatched';

type MatchLexicalItemsProps = {
  exerciseId: DBId;
  language: LanguageV2;
  values: LexicalItemMatch[];
};

export const LexicalItemsMatched = ({ exerciseId, language, values }: MatchLexicalItemsProps) => {
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const { isEditAvailable } = useIsEditAvailable();

  const [currentValues, setCurrentValues] = useState(mapLexicalItemsMatchedToValues(values, language));
  const [areInvalidValues, setAreInvalidValues] = useState(false);

  const {
    checkLexicalItemsDuplicates,
    createAndMatchLexicalItem,
    matchLexicalItem,
    onLexicalItemClick,
    onSearch,
    unmatchLexicalItem,
  } = useLexicalItemsMatched({
    exerciseId,
    language,
  });

  const theme = useTheme();

  useEffect(() => {
    const areInvalidValues = currentValues.length > 0 && currentValues.some((value) => !value.valid);

    setAreInvalidValues(areInvalidValues);
  }, [currentValues]);

  if (!isFeatureEnabled('vocabularyReview')) return null;

  const onChange = (newValues: MatchLexicalItemCreatorOption[]) => {
    if (isEqual(newValues, currentValues)) return;

    let nextCurrentValues = [...newValues];

    if (newValues.length > currentValues.length) {
      // item added
      const [targetLexicalItem] = difference(newValues, currentValues);
      const { label: phrase, value: lexicalItemId } = targetLexicalItem;

      if (!lexicalItemId || lexicalItemId === LEXICAL_ITEM_TMP_ID) {
        // create a new Lexical Item and attach it to the exercise
        createAndMatchLexicalItem({ nextCurrentValues, phrase });
      } else {
        // attach existing Lexical Item to the exercise
        matchLexicalItem({ exerciseId, lexicalItemId, phrase });
      }
    } else {
      // item removed
      const [targetLexicalItem] = difference(currentValues, newValues);
      const { label: phrase, value: lexicalItemId } = targetLexicalItem;

      // dettach it from the exercise
      unmatchLexicalItem({ exerciseId, lexicalItemId, phrase });
    }

    setCurrentValues(nextCurrentValues);
  };

  const handleOnSearch = (query = '') =>
    onSearch(query, ({ lexicalItemId, phrase, ready }) => {
      const newValues = [...currentValues, { label: phrase, valid: ready, value: lexicalItemId }];
      onChange(newValues);
    });

  return (
    <LexicalItemsMatchedWrapper>
      <Text color={theme.colorV2.textSecondary} level={3} spaceBottom="XS">
        Lexical items matched
      </Text>
      {areInvalidValues && <StyledValidationErrorDisplayer text="Lexical Items not valid" />}
      <MatchLexicalItemCreator
        isEditAvailable={isEditAvailable}
        language={language}
        showErrored={isIssuesShown}
        values={currentValues}
        checkDuplicates={checkLexicalItemsDuplicates}
        onChange={onChange}
        onClick={onLexicalItemClick}
        onSearch={handleOnSearch}
      />
    </LexicalItemsMatchedWrapper>
  );
};
