import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import TypingExerciseInterface from '@components/Exercises/Typing/interfaces/TypingExerciseInterface';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editTypingExercise } from '@services/exercises/editExerciseService';
import { EditTypingExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';
import { userTracking } from '@features/app/tracking';

type TypingExerciseServiceType = ExerciseService<TypingExerciseInterface>;

const TypingExerciseService: TypingExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: TypingExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['feedback', 'instructions', 'hint', 'lexicalItems', 'mainBundle'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async save(
    dispatch: AppDispatch,
    exercise: TypingExerciseInterface,
    values: FormikValuesInterface,
    learningLanguage: string | undefined,
  ) {
    try {
      let payload: EditTypingExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        mainImageEnabled: exercise.content.mainImageEnabled,
        mainAudioEnabled: exercise.content.mainAudioEnabled,
        recapExerciseId: exercise.content.recapExerciseId,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.hintChanged || exercise.content.hint?.changed || exercise.content.hintChanged) &&
        !exercise.content.hint?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'hint', exercise, payload)
          : { hint: exercise.content.hint?._id || null },
        (values.feedbackChanged || exercise.content.feedback?.changed || exercise.content.feedbackChanged) &&
        !exercise.content.feedback?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'feedback', exercise, payload)
          : { feedback: exercise.content.feedback?._id || null },
        values.mainBundle_phraseChanged ||
        exercise.content?.mainBundle?.isVocabularyChanged ||
        exercise.content?.mainBundle?.image?.changed ||
        exercise.content?.mainBundle?.imageChanged ||
        exercise.content?.mainBundle?.phrase?.changed ||
        exercise.content?.mainBundle?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'mainBundle', exercise, payload, values)
          : null,
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
      };

      const phraseAlternativeValues =
        exercise.content?.mainBundle?.phrase?.textLocalizations?.find(
          (localization) => localization.language === learningLanguage,
        )?.alternativeValues || [];

      if (exercise.content.id) {
        await editTypingExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });

        if (exercise.content?.mainBundle?.phrase?.changed && phraseAlternativeValues.length) {
          phraseAlternativeValues.forEach(() => {
            userTracking.logosAlternativeValueCreated({
              exercise_id: exercise.content.id as string,
              exercise_type: exercise.content.type,
            });
          });
        }
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default TypingExerciseService;
