import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import MultipleChoiceExerciseInterface from '@components/Exercises/MultipleChoice/interfaces/MultipleChoiceExerciseInterface';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editMultipleChoiceExercise } from '@services/exercises/editExerciseService';
import { EditMultipleChoiceExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

type MultipleChoiceExerciseServiceType = ExerciseService<MultipleChoiceExerciseInterface>;

const MultipleChoiceExerciseService: MultipleChoiceExerciseServiceType = {
  async save(dispatch: AppDispatch, exercise: MultipleChoiceExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditMultipleChoiceExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        choicesLanguage: exercise.content.choicesLanguage as DisplayedLanguageType,
        mainImageEnabled: exercise.content.mainImageEnabled,
        mainAudioEnabled: exercise.content.mainAudioEnabled,
        choicesImagesEnabled: exercise.content.choicesImagesEnabled,
        recapExerciseId: exercise.content.recapExerciseId,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.feedbackChanged || exercise.content.feedback?.changed || exercise.content.feedbackChanged) &&
        !exercise.content.feedback?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'feedback', exercise, payload)
          : { feedback: exercise.content.feedback?._id || null },
        values.answerBundle_phraseChanged ||
        exercise.content.answerBundle?.phrase?.changed ||
        exercise.content.answerBundle?.image?.changed ||
        exercise.content.answerBundle?.imageChanged ||
        exercise.content.answerBundle?.isVocabularyChanged ||
        exercise.content?.answerBundle?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'answerBundle', exercise, payload, values)
          : null,
      ]);

      const distractorsPayload = await Promise.all([
        values.distractor1_phraseChanged ||
        exercise.content.distractor1?.phrase?.changed ||
        exercise.content.distractor1?.image?.changed ||
        exercise.content.distractor1?.imageChanged ||
        exercise.content.distractor1?.isVocabularyChanged ||
        exercise.content?.distractor1?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'distractor1', exercise, {}, values)
          : { distractor1: exercise.content?.distractor1?._id || null },
        values.distractor2_phraseChanged ||
        exercise.content.distractor2?.phrase?.changed ||
        exercise.content.distractor2?.image?.changed ||
        exercise.content.distractor2?.imageChanged ||
        exercise.content.distractor2?.isVocabularyChanged ||
        exercise.content?.distractor2?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'distractor2', exercise, {}, values)
          : { distractor2: exercise.content?.distractor2?._id || null },
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
        distractors: [distractorsPayload[0].distractor1, distractorsPayload[1].distractor2],
      };

      if (exercise.content.id) {
        await editMultipleChoiceExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: MultipleChoiceExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['answerBundle', 'distractor1', 'distractor2', 'feedback', 'instructions', 'lexicalItems'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
};

export default MultipleChoiceExerciseService;
