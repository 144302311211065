import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';

import { ExerciseCommonActionCreators } from '@actionCreators/ExerciseCommonActionCreator';
import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { DBId } from '@common/types/DBId';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { apiClient } from '@features/api';
import { FormikValueInterface } from '@helpers/formikInitialValuesHelper';
import { selectMediaLocalizationsForSave, selectTextLocalizationsForSave } from '@helpers/localizationSaveHelper';
import ContentsService from '@services/ContentsService';

import type {
  LexicalItem,
  LexicalItemContentType,
  LexicalItemFieldWithLocalizationNames,
  LexicalItemFormikValues,
  MatchedExercisesLocations,
  SaveLexicalItemPayload,
  VocabularyReviewListItem,
  VocabularyReviewViewParams,
} from './types';
import { mapWordsRangeValueToApiValue } from './VocabularyReview/util';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { AppDispatch } from '@redux/store';

export const VocabularyReviewService = {
  async getLexicalItems({
    language,
    filters,
    page,
  }: VocabularyReviewViewParams): Promise<{ count: number; results: VocabularyReviewListItem[] }> {
    let _filters = { ...filters };

    if (_filters.wordsRange) {
      _filters = {
        ..._filters,
        ...mapWordsRangeValueToApiValue(_filters.wordsRange),
      };

      delete _filters.wordsRange;
    }

    const response = await apiClient.noErrorsV2.get(`search/lexical-items`, {
      params: {
        language,
        page,
        ..._filters,
      },
    });

    return response.data;
  },
  async getSpeechParts(): Promise<AxiosResponse<{ speechParts: string[] }>> {
    return await apiClient.noErrorsV2.get('content/lexical-items/speech-parts');
  },
  async getExercisesMatchedLocations({
    lexicalItemId,
  }: {
    lexicalItemId: DBId;
  }): Promise<AxiosResponse<MatchedExercisesLocations>> {
    /** @TODO Remove this mock and enable the production code when endpoint is available */
    const mockResponse = {
      data: Array(30).fill([
        {
          id: 'course_pack_en_onboarding',
          type: 'course',
          fieldStoredAs: null,
          language: 'EN',
          label: 'Busuu Internal Onboarding',
        },
        {
          id: '5518ac2e-72a5-467a-b241-0d3883eac0c8',
          type: 'group',
          fieldStoredAs: 'groups',
          groupType: 'level',
          label: 'Test level - Santi',
        },
        {
          id: '47e09a91-0a2b-4664-a8c0-c9fb892db618',
          type: 'group',
          fieldStoredAs: 'groups',
          groupType: 'chapter',
          label: 'Introductions',
        },
        {
          id: '19bb3481-6b35-4c6d-9d84-20f969a0f216',
          type: 'group',
          fieldStoredAs: 'groups',
          groupType: 'lesson',
          label: 'Giving your nationality',
        },
        {
          id: 'df290783-eaed-4ba1-a17b-355cde91af9b',
          type: 'group',
          fieldStoredAs: 'groups',
          groupType: 'activity',
          label: 'Vocabulary',
        },
        {
          id: 'd5499259-5611-4aae-a9d9-c978585ff7c0',
          type: 'exercise',
          fieldStoredAs: 'exercises',
          exerciseType: 'flashcard',
          label: 'Flashcard',
        },
      ]),
      status: 200,
    };
    return new Promise((resolve) => setTimeout(() => resolve(mockResponse as AxiosResponse), 1000));
  },
  async getLexicalItem(lexicalItemId: DBId): Promise<AxiosResponse<{ lexicalItem: LexicalItem }>> {
    return await apiClient.noErrorsV2.get(`content/lexical-items/${lexicalItemId}`);
  },
  async updateLexicalItemContentField(
    lexicalItemContent: LexicalItemContentType,
    fieldName: LexicalItemFieldWithLocalizationNames,
    values: LexicalItemFormikValues,
    contentType: ContentTypesType,
    setContentId: (
      contentType: ContentTypesType,
      fieldName: LexicalItemFieldWithLocalizationNames,
      contentId: DBId,
    ) => void,
  ) {
    const contentFieldName = lexicalItemContent[
      fieldName as keyof LexicalItemContentType
    ] as TranslationsPanelContentInterface;
    const valueFieldName = values[fieldName as keyof LexicalItemFormikValues] as FormikValueInterface[];

    const textLocalizationsFilledFromFormik = contentFieldName?.textLocalizations.map((loc) => ({
      ...loc,
      value: valueFieldName.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
      phoneticValue: valueFieldName.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
    }));

    const contentPayload = {
      description: values[`${fieldName}Context` as keyof LexicalItemFormikValues] || '',
      textLocalizations: selectTextLocalizationsForSave(textLocalizationsFilledFromFormik || []),
      audioLocalizations: selectMediaLocalizationsForSave(contentFieldName?.audioLocalizations || []),
      imageLocalizations: [],
      videoLocalizations: [],
    };
    if (
      !contentPayload.textLocalizations.length &&
      !contentPayload.audioLocalizations.length &&
      !contentPayload.description
    ) {
      return { [fieldName]: null };
    }

    let contentId = contentFieldName?.id || contentFieldName?._id || '';

    try {
      if (!contentId) {
        contentId = await ContentsService.contents.createNewContent(contentPayload);

        setContentId(contentType, fieldName, contentId);
      } else {
        await ContentsService.contents.update(contentId, contentPayload);
      }
    } catch (error) {
      Sentry.captureException(error, (scope) => {
        scope.setTag('logosSection', 'Lexical Item');
        scope.setExtras({
          contentType,
          fieldName,
        });

        return scope;
      });
    }

    return {
      [fieldName]: contentId,
    };
  },
  async getPayloadForLexicalItemUpdate(
    lexicalItemContent: LexicalItemContentType,
    values: LexicalItemFormikValues,
    setContentId: (
      contentType: ContentTypesType,
      fieldName: LexicalItemFieldWithLocalizationNames,
      contentId: DBId,
    ) => void,
  ) {
    const isPhraseChanged =
      (values?.phraseChanged || lexicalItemContent.phraseChanged) && !lexicalItemContent.phrase.isReused;
    const isExampleChanged =
      (values?.exampleChanged || lexicalItemContent.exampleChanged) && !lexicalItemContent.example.isReused;

    const payloadAccordingChangedFields: Partial<
      Record<LexicalItemFieldWithLocalizationNames | 'image', string | null>
    >[] = [];

    if (isPhraseChanged) {
      const phrase = await VocabularyReviewService.updateLexicalItemContentField(
        lexicalItemContent,
        'phrase',
        values,
        ContentTypes.lexicalItem,
        setContentId,
      );

      payloadAccordingChangedFields.push(phrase);
    }

    if (isExampleChanged) {
      const phrase = await VocabularyReviewService.updateLexicalItemContentField(
        lexicalItemContent,
        'example',
        values,
        ContentTypes.lexicalItem,
        setContentId,
      );

      payloadAccordingChangedFields.push(phrase);
    }

    if (lexicalItemContent.imageChanged) {
      payloadAccordingChangedFields.push({});
    }

    const payload: Partial<Record<LexicalItemFieldWithLocalizationNames | 'image', string | null>> = {
      ...payloadAccordingChangedFields.reduce((sum, item) => ({ ...sum, ...item }), {}),
    };

    return payload;
  },
  async createLexicalItem({
    exerciseId,
    language,
    phrase,
    dispatch,
  }: {
    exerciseId?: DBId;
    language: DBId;
    phrase: string;
    dispatch: AppDispatch;
  }): Promise<string | undefined> {
    const contentPayload = {
      audioLocalizations: [],
      description: 'Lexical item phrase',
      textLocalizations: [],
    };
    const isFromExercise = Boolean(exerciseId);

    if (isFromExercise) {
      const emptyPhraseLocalizations = ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
        'textLocalizations',
        'audioLocalizations',
      ]);

      contentPayload.audioLocalizations = emptyPhraseLocalizations.audioLocalizations;
      contentPayload.textLocalizations = emptyPhraseLocalizations.textLocalizations.map((loc: LocalizationInterface) =>
        loc.language === language ? { ...loc, value: phrase } : loc,
      );
    } else {
      /** @TODO Extend to support creation from Lexical Item view */
    }

    const phraseId = await ContentsService.contents.createNewContent(contentPayload);

    if (phraseId) {
      const createResult = await apiClient.noErrorsV2.post(`content/lexical-items/${language}`, { phrase: phraseId });

      if (createResult.status === 200) {
        const newLexicalItemId = createResult.data.id;

        if (isFromExercise) {
          dispatch(
            ExerciseCommonActionCreators.setExerciseLexicalItem({
              lexicalItemId: newLexicalItemId,
              phrase: contentPayload,
            }),
          );

          VocabularyReviewService.attachLexicalItemToExercise(newLexicalItemId, { exerciseId: exerciseId as DBId });
        }

        return newLexicalItemId;
      }
    }
  },
  async saveLexicalItem(lexicalItemId: DBId, payload: Partial<SaveLexicalItemPayload>) {
    return await apiClient.noErrorsV2.put(`content/lexical-items/${lexicalItemId}`, payload);
  },
  async attachLexicalItemToExercise(lexicalItemId: DBId, payload: { exerciseId: DBId }) {
    return await apiClient.noErrorsV2.post(`content/lexical-items/${lexicalItemId}/attach-to-exercise`, payload);
  },
  async dettachLexicalItemToExercise(lexicalItemId: DBId, payload: { exerciseId: DBId }) {
    return await apiClient.noErrorsV2.post(`content/lexical-items/${lexicalItemId}/detach-from-exercise`, payload);
  },
};
