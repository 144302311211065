import { LanguageV2 } from '@features/content/languages';
import { LexicalItemMatch, MatchLexicalItemCreatorOption } from '@features/content/vocabularyReview';

export const mapLexicalItemsMatchedToValues = (
  lexicalItemsMatched: LexicalItemMatch[],
  language: LanguageV2,
): MatchLexicalItemCreatorOption[] =>
  lexicalItemsMatched.length
    ? lexicalItemsMatched.map((lexicalItem) => {
        const phrase = lexicalItem.phrase.textLocalizations?.find((localization) => localization.language === language);
        const { validationStatus } = lexicalItem;

        return {
          label: phrase?.value || '',
          valid: validationStatus?.valid ?? false,
          value: lexicalItem.id,
        };
      })
    : [];
