import { Fragment, useCallback, useState } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { LoadedSpeechRecognitionExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import MultiOptionChooser from './MultiOptionChooser/MultiOptionChooser';
import { NonWritableInputText, WritableInputText } from '@components/WritableInputText';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { getResources } from '@helpers/getResourcesHelper';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { PreviewModal } from '@features/content/preview';
import { PhonemeTable } from './PhonemeTable/PhonemeTable';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExperimentSelector } from '@features/experiment';
import { constants as contentConstants, findLocalizationInSearchedLanguage } from '@features/content';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { VideoUploadActionsCreator } from '@actionCreators/VideoUploadActionsCreator';
import { AudioUpload, ImageUpload, VideoUpload } from '@components/MediaUpload';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { Sizes } from '@common/enums/Sizes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { SpeechRecognitionExerciseActionsCreator } from '@actionCreators/SpeechRecognitionExerciseActionsCreator';
import SpeechRecognitionExerciseInterface from './interfaces/SpeechRecognitionExerciseInterface';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { Instructions, LexicalItemsMatched, removeMediaProcessingValidationError } from '@features/content/exercises';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';
import { DBId } from '@common/types/DBId';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

const SpeechRecognitionExercise = ({
  exercise: exerciseParam,
}: ExerciseProps<LoadedSpeechRecognitionExerciseInterface>) => {
  const { isEditorHidden } = useIsEditorHidden({ visitedBranch: 'feedback' });
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as SpeechRecognitionExerciseInterface;

  const audioLocalizations = loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableAudioLocalizations = audioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  // Image and Video only have EN localization
  const imageLocalization = loadedExercise?.content?.mainBundle?.image?.imageLocalizations?.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );
  const videoLocalization = loadedExercise?.content?.mainBundle?.video?.videoLocalizations?.find(
    (videoLocalization) => videoLocalization.language === DEFAULT_LANGUAGE_V2,
  );

  const [phonemeTableVisible, setPhonemeTableVisible] = useState(false);

  let [multiOptionChooserOptionAs, setMultiOptionChooserOptionAs] = useState<'text' | 'phoneme' | undefined>(() => {
    if (loadedExercise.content.feedbackOption === 'phoneme') return 'phoneme';
    if (loadedExercise.content.feedbackOption === 'text') return 'text';

    return undefined;
  });

  const languageWhereMultichooserAppears = DEFAULT_LANGUAGE_V2;

  const { isEditAvailable } = useIsEditAvailable();

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle');
  const mainImageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.image');

  if (mainImageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const mainVideoErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.video');

  if (mainVideoErrors.length && videoLocalization?.processed && videoLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.VIDEO.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const mainAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' && error.message.toLowerCase().includes('audio'),
  );

  if (mainAudioErrors.length) {
    let updatedErrors = [...errors];

    availableAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const mainContentTextErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'mainBundle.phrase' && error.message.includes('text'),
  );
  const mainContentErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' &&
      !error.message.includes('text') &&
      !error.message.toLowerCase().includes('audio'),
  );
  const feedbackOptionErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'feedbackOption');
  const feedbackErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'feedback');

  const isMainContentChangeBlocked =
    (loadedExercise.content.mainBundle?.phrase?.mappings?.count > 1 ||
      loadedExercise.content.mainBundle?.phrase?.mappings?.length > 1) &&
    !loadedExercise.content.mainBundle?.phrase?.isReusingConfirmed;

  const isMainBundleChangeBlocked =
    loadedExercise.content.mainBundle?.mappings?.count &&
    loadedExercise.content.mainBundle?.mappings?.count > 1 &&
    !loadedExercise.content.mainBundle?.isReusingConfirmed;

  const feedback = (
    <ContentToggler
      text="Explanation"
      open={!!loadedExercise.content.feedbackOption}
      onRemove={() => {
        dispatch(CommonExerciseActionsCreator.nullifyExplanation());
        dispatch(SpeechRecognitionExerciseActionsCreator.removeMultiOptionChooserOption());
        setMultiOptionChooserOptionAs(undefined);
        setFieldValue(
          `feedback`,
          values.feedback?.map((item: any) => ({ ...item, value: '' })),
        );
        setFieldValue(`feedbackChanged`, true);
      }}
      onSwitch={(notRemoved: boolean) => {
        if (notRemoved) {
          dispatch(SpeechRecognitionExerciseActionsCreator.setFeedbackOption());
          setFieldValue(
            `feedback`,
            values.feedback?.map((item: any) => ({ ...item, value: '' })),
          );
          setFieldValue(`feedbackChanged`, true);
        }
      }}
      errors={feedbackOptionErrors.length ? feedbackOptionErrors : feedbackErrors}
    >
      <TranslationsTipV2
        visitedBranch={'feedback'}
        showLanguageSwitcher={false}
        errors={feedbackErrors}
        showErrorDisplayer={false}
        flipped={true}
        translationsPanelTranslationsMode={TranslationsPanelModes.wysiwyg}
      >
        <BidimensionalEditor
          mode={BidimensionalEditorModes.mono}
          storeBranch="feedback"
          language={DEFAULT_LANGUAGE_V2}
          maxColums={1}
          dataSourceMode={'editorState'}
          ignoreLanguageForTips={true}
          toolbar={{
            options: ['inline'],
            inline: {
              options: ['bold'],
            },
          }}
          charactersLimit={120}
        />
      </TranslationsTipV2>
    </ContentToggler>
  );

  const feedbackComponent = isEditorHidden ? <div>{feedback}</div> : feedback;

  const requestOrCancelAudioPayload = {
    bundleName: 'mainBundle',
    field: 'phrase',
    language: courseLearningLanguage,
    type: ContentTypes.exercise,
  };

  const setReusedData = (id: string, field: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        'mainBundle',
        isBundle,
      ),
    );
  };

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.exercise,
          field: 'phrase',
          language: courseLearningLanguage,
          bundleName: 'mainBundle',
        }),
      );
    },
    [dispatch, courseLearningLanguage],
  );

  return (
    <div className="exercise-speech-recognition">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Speech Recognition</Title>
        <HelpDisplayer type="guideline" id="guideline-speech-recognition-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-speech-recognition__main-wrapper">
        <div className="exercise-speech-recognition__wrapper">
          <div className="exercise-speech-recognition__container">
            <h1 className="exercise-speech-recognition__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(SpeechRecognitionExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </div>

        <LexicalItemsMatched
          exerciseId={loadedExercise.content.id as DBId}
          language={courseLearningLanguage}
          values={loadedExercise.content.lexicalItems ?? []}
        />

        <ResourceBundleWrapper
          bundle={loadedExercise.content.mainBundle}
          bundleName={'mainBundle'}
          errors={mainBundleErrors}
          resources={getResources(loadedExercise, courseLearningLanguage)}
        >
          <>
            <div className="exercise-speech-recognition__wrapper">
              <div className="exercise-speech-recognition__container">
                <span className="exercise-speech-recognition__box-title">Image</span>
                <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(mainImageErrors)} />
              </div>
              <ImageUpload
                width={ImageUploadDimensionDescriptors.speechRecognition.image.width}
                height={ImageUploadDimensionDescriptors.speechRecognition.image.height}
                size={Sizes.fullscreen}
                mode={ImageUploadModes.normal}
                onChange={(file: File, progressHandler: Function) => {
                  if (file !== null && loadedExercise.content.id !== undefined) {
                    dispatch(SpeechRecognitionExerciseActionsCreator.setImage(loadedExercise, file, progressHandler));
                  }
                }}
                onRemove={() => {
                  dispatch(SpeechRecognitionExerciseActionsCreator.removeImage());
                }}
                imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                  loadedExercise,
                  'image',
                  undefined,
                  'mainBundle',
                )}
                onProcessingFinished={(url: string) => {
                  dispatch(
                    CommonExerciseActionsCreator.setValueAfterProcessing({
                      url,
                      mediaType: 'image',
                      type: ContentTypes.exercise,
                      field: 'image',
                      language: 'EN',
                      bundleName: 'mainBundle',
                    }),
                  );
                }}
                previewMode={false}
                isForExercise
                errors={mainImageErrors}
                isChangeBlocked={!!isMainBundleChangeBlocked}
                onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true)}
              />
            </div>
            <div className="exercise-speech-recognition__wrapper">
              <div className="exercise-speech-recognition__container">
                <h3 className="exercise-speech-recognition__box-title">Audio</h3>
                <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(mainAudioErrors)} />
              </div>
              <AudioUpload
                audioData={ImageUploadUtils.getAudioForFileUpload(
                  loadedExercise,
                  'phrase',
                  courseLearningLanguage,
                  'mainBundle',
                )}
                onAudioRequestSuccess={(audioRequestId: string) => {
                  dispatch(
                    CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                      ...requestOrCancelAudioPayload,
                      audioRequestData: {
                        id: audioRequestId,
                        status: 'new',
                      },
                    }),
                  );
                }}
                onCancelAudioRequestSuccess={() => {
                  dispatch(
                    CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
                  );
                }}
                onProcessingFinished={onProcessingFinished}
                onChange={(uploadedSound, progressHandler) => {
                  dispatch(
                    AudioUploadActionsCreator.uploadSound(
                      loadedExercise.content.id,
                      ExerciseTypes.speechRecognition,
                      courseLearningLanguage,
                      loadedExercise.content.mainBundle?.phrase?._id,
                      findLocalizationInSearchedLanguage(
                        loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations,
                        courseLearningLanguage,
                      ),
                      uploadedSound,
                      'phrase',
                      undefined,
                      undefined,
                      true,
                      'mainBundle',
                      progressHandler,
                    ),
                  );
                }}
                onChangeInstant={() =>
                  isMainBundleChangeBlocked
                    ? setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true)
                    : setReusedData(
                        loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id ||
                          '',
                        'phrase',
                        false,
                      )
                }
                isChangeBlocked={isMainBundleChangeBlocked || isMainContentChangeBlocked}
                onRemove={() => {
                  dispatch(
                    SpeechRecognitionExerciseActionsCreator.removeAudio({
                      contentId:
                        loadedExercise.content.mainBundle?.phrase?._id || loadedExercise.content.mainBundle?.phrase?.id,
                      language: courseLearningLanguage,
                    }),
                  );
                }}
                fullScreen
                errors={mainAudioErrors}
                currentLanguage={courseLearningLanguage}
                fieldName={'phrase'}
              />
            </div>
            <div className="exercise-speech-recognition__wrapper">
              <div className="exercise-speech-recognition__container">
                <h1 className="exercise-speech-recognition__box-title">Phrase</h1>
                <ValidationErrorDisplayer
                  type={
                    [...mainContentErrors, ...mainContentTextErrors].some((e) => !e.isWarning) ? 'error' : 'warning'
                  }
                  text={helpersService.getValidationErrorMessageText([...mainContentErrors, ...mainContentTextErrors])}
                />
              </div>
              <TranslationsTipV2
                bundleName={'mainBundle'}
                visitedBranch={'phrase'}
                errors={[...mainContentErrors, ...mainContentTextErrors]}
                showErrorDisplayer={false}
              >
                <WritableInputText bold fontSize="24" id="speech-recognition-phrase-input" />
              </TranslationsTipV2>
            </div>

            <ContentToggler
              text="Video"
              open={!!loadedExercise?.content?.mainBundle?.video?._id}
              onRemove={() => dispatch(SpeechRecognitionExerciseActionsCreator.removeVideo())}
              errors={mainVideoErrors}
            >
              <VideoUpload
                videoData={ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')}
                onChange={(uploadedVideo, progressHandler) => {
                  dispatch(
                    VideoUploadActionsCreator.uploadVideo(loadedExercise, uploadedVideo, 'mainBundle', progressHandler),
                  );
                }}
                onProcessingFinished={(url) => {
                  dispatch(
                    CommonExerciseActionsCreator.setValueAfterProcessing({
                      url,
                      mediaType: 'video',
                      type: ContentTypes.exercise,
                      field: 'video',
                      language: 'EN',
                      bundleName: 'mainBundle',
                    }),
                  );
                }}
                onRemove={() =>
                  isMainBundleChangeBlocked
                    ? setReusedData(loadedExercise.content.mainBundle?._id || '', 'video', true)
                    : dispatch(SpeechRecognitionExerciseActionsCreator.removeVideo())
                }
                isChangeBlocked={!!isMainBundleChangeBlocked}
                fullScreen
                errors={[]}
                fieldName="video"
                onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'video', true)}
              />
            </ContentToggler>
          </>
        </ResourceBundleWrapper>

        {courseLearningLanguage === languageWhereMultichooserAppears && (
          <Fragment>
            {multiOptionChooserOptionAs === undefined && (
              <div className="exercise-speech-recognition__wrapper">
                <MultiOptionChooser
                  onClick={(optionClicked: number) => {
                    switch (optionClicked) {
                      case 1: {
                        setMultiOptionChooserOptionAs('text');
                        dispatch(SpeechRecognitionExerciseActionsCreator.setMultiOptionChooserOptionAs('text'));
                        break;
                      }
                      case 2: {
                        setMultiOptionChooserOptionAs('phoneme');
                        dispatch(SpeechRecognitionExerciseActionsCreator.setMultiOptionChooserOptionAs('phoneme'));
                        setPhonemeTableVisible(true);
                        break;
                      }
                    }
                  }}
                />
              </div>
            )}
            {multiOptionChooserOptionAs === 'phoneme' && (
              <Fragment>
                <ContentToggler
                  text="Phoneme to test"
                  open={true}
                  onRemove={() => {
                    dispatch(SpeechRecognitionExerciseActionsCreator.removeMultiOptionChooserOption());
                    setMultiOptionChooserOptionAs(undefined);
                  }}
                >
                  <div
                    className="exercise-speech-recognition__selected-phonemes"
                    onClick={() => setPhonemeTableVisible(true)}
                  >
                    {loadedExercise.content.feedbackPhonemes !== null &&
                      loadedExercise.content.feedbackPhonemes.map((phoneme: string, idx: number) => (
                        <NonWritableInputText
                          key={idx}
                          value={phoneme}
                          className="exercise-speech-recognition__selected-phoneme"
                        />
                      ))}
                  </div>
                </ContentToggler>

                <PhonemeTable
                  show={phonemeTableVisible}
                  onSave={(phonemes: string[]) => {
                    dispatch(SpeechRecognitionExerciseActionsCreator.updatePhonemes(phonemes));
                  }}
                  preselectedPhonemes={loadedExercise.content.feedbackPhonemes}
                  close={() => {
                    setPhonemeTableVisible(false);
                  }}
                />
              </Fragment>
            )}
            {multiOptionChooserOptionAs === 'text' && feedbackComponent}
          </Fragment>
        )}

        {courseLearningLanguage !== languageWhereMultichooserAppears && <Fragment>{feedbackComponent}</Fragment>}
      </div>
      <RecapSelector exerciseId={loadedExercise?.content?.id} />
    </div>
  );
};

export default SpeechRecognitionExercise;
