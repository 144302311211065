import { FunctionComponent, useCallback } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { LoadedFlashCardExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { AlternativeValueCreator } from '@components/Exercises/Common/AlternativeValueCreator/AlternativeValueCreator';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { WritableInputText } from '@components/WritableInputText';
import { VideoUploadActionsCreator } from '@actionCreators/VideoUploadActionsCreator';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { getResources } from '@helpers/getResourcesHelper';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { PreviewModal } from '@features/content/preview';
import { constants as contentConstants, findLocalizationInSearchedLanguage } from '@features/content';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { AudioUpload, ImageUpload, VideoUpload } from '@components/MediaUpload';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExperimentSelector } from '@features/experiment';
import { AccessWarning } from '@components/Warning';
import { Sizes } from '@common/enums/Sizes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { FlashcardExerciseActionsCreator } from '@actionCreators/FlashcardExerciseActionsCreator';
import FlashcardExerciseInterface from './interfaces/FlashCardExerciseInterface';
import { DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import { Instructions, LexicalItemsMatched, removeMediaProcessingValidationError } from '@features/content/exercises';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';
import { apiClient } from '@features/api';
import { DBId } from '@common/types/DBId';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

/** @TODO Move this to a service to be reused */
const getAlternativeValueSuggestions = async (language: LanguageV2, phrase: string): Promise<string[]> => {
  const response = await apiClient.noErrorsV2.post('ai/alternative-values', {
    phrase,
    language,
  });

  return response.data.alternativeValues as string[];
};

const Flashcard: FunctionComponent<ExerciseProps<LoadedFlashCardExerciseInterface>> = ({
  exercise: exerciseParam,
}: ExerciseProps<LoadedFlashCardExerciseInterface>) => {
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as FlashcardExerciseInterface;

  const phraseAudioLocalizations = loadedExercise?.content?.learningWordBundle?.phrase.audioLocalizations;
  const exampleAudioLocalizations = loadedExercise?.content?.learningWordBundle?.example?.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availablePhraseAudioLocalizations = phraseAudioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );
  const availableExampleAudioLocalizations = exampleAudioLocalizations?.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  // Image and Video only have EN localization
  const imageLocalization = loadedExercise?.content?.learningWordBundle?.image?.imageLocalizations?.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );
  const videoLocalization = loadedExercise?.content?.learningWordBundle?.video?.videoLocalizations?.find(
    (videoLocalization) => videoLocalization.language === DEFAULT_LANGUAGE_V2,
  );

  const { isEditAvailable } = useIsEditAvailable();

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainImageErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'learningWordBundle.image',
  );

  if (mainImageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const mainVideoErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'learningWordBundle.video',
  );

  if (mainVideoErrors.length && videoLocalization?.processed && videoLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.VIDEO.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const learningWordErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'learningWordBundle');
  const learningWordPhraseErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'learningWordBundle.phrase',
  );
  const learningWordTextErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'learningWordBundle.phrase' && error.message.includes('text'),
  );
  const learningWordAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'learningWordBundle.phrase' && error.message.toLowerCase().includes('audio'),
  );

  if (learningWordAudioErrors.length) {
    let updatedErrors = [...errors];

    availablePhraseAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          fieldName: 'learningWordBundle.phrase',
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const learningExampleErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'learningWordBundle.example',
  );
  const learningExampleTextErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'learningWordBundle.example' && error.message.includes('text'),
  );
  const learningExampleAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'learningWordBundle.example' && error.message.toLowerCase().includes('audio'),
  );

  if (learningExampleAudioErrors.length) {
    let updatedErrors = [...errors];

    // example audio localizations might not exist if the example field has previously removed
    (availableExampleAudioLocalizations ?? []).forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          fieldName: 'learningWordBundle.example',
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const isLearningWordChangeBlocked =
    (loadedExercise.content.learningWordBundle?.phrase?.mappings?.count > 1 ||
      loadedExercise.content.learningWordBundle?.phrase?.mappings?.length > 1) &&
    !loadedExercise.content.learningWordBundle?.phrase?.isReusingConfirmed;
  const isLearningExampleChangeBlocked =
    (loadedExercise.content.learningWordBundle?.example?.mappings?.count > 1 ||
      loadedExercise.content.learningWordBundle?.example?.mappings?.length > 1) &&
    !loadedExercise.content.learningWordBundle?.example?.isReusingConfirmed;

  const isLearningWordBundleChangeBlocked =
    loadedExercise.content.learningWordBundle?.mappings?.count &&
    loadedExercise.content.learningWordBundle?.mappings?.count > 1 &&
    !loadedExercise.content.learningWordBundle?.isReusingConfirmed;

  const requestOrCancelAudioPayload = {
    bundleName: 'learningWordBundle',
    language: courseLearningLanguage,
    type: ContentTypes.exercise,
  };

  const setReusedData = (id: string, field: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        'learningWordBundle',
        isBundle,
      ),
    );
  };

  const onProcessingFinishedPhrase = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.exercise,
          field: 'phrase',
          language: courseLearningLanguage,
          bundleName: 'learningWordBundle',
        }),
      );
    },
    [dispatch, courseLearningLanguage],
  );

  const onProcessingFinishedExample = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.exercise,
          field: 'example',
          language: courseLearningLanguage,
          bundleName: 'learningWordBundle',
        }),
      );
    },
    [dispatch, courseLearningLanguage],
  );

  const learningLanguagePhrase = (values.learningWordBundle_phrase || []).find(
    (loc) => loc.language === courseLearningLanguage,
  );
  const loadSuggestions = useCallback(async () => {
    if (!learningLanguagePhrase?.value) {
      return [];
    }

    return getAlternativeValueSuggestions(courseLearningLanguage as LanguageV2, learningLanguagePhrase.value);
  }, [learningLanguagePhrase, courseLearningLanguage]);

  return (
    <div className="exercise-flashcard">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Flashcard</Title>
        <HelpDisplayer type="guideline" id="guideline-flashcard-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-flashcard__main-wrapper">
        <div className="exercise-flashcard__wrapper">
          <div className="exercise-flashcard__container">
            <h1 className="exercise-flashcard__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) =>
              dispatch(FlashcardExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode))
            }
          />
        </div>

        <LexicalItemsMatched
          exerciseId={loadedExercise.content.id as DBId}
          language={courseLearningLanguage}
          values={loadedExercise.content.lexicalItems ?? []}
        />

        <ResourceBundleWrapper
          bundle={loadedExercise.content.learningWordBundle}
          bundleName={'learningWordBundle'}
          errors={learningWordErrors}
          resources={getResources(loadedExercise, courseLearningLanguage)}
        >
          <>
            <div className="exercise-flashcard__wrapper">
              <div className="exercise-flashcard__container">
                <span className="exercise-flashcard__box-title">Image</span>
                <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(mainImageErrors)} />
              </div>

              <ImageUpload
                width={ImageUploadDimensionDescriptors.flashcard.image.width}
                height={ImageUploadDimensionDescriptors.flashcard.image.height}
                size={Sizes.fullscreen}
                mode={ImageUploadModes.normal}
                onChange={(file: File, progressHandler: Function) => {
                  if (file !== undefined && loadedExercise.content.id !== undefined) {
                    dispatch(FlashcardExerciseActionsCreator.setFlashcardImage(loadedExercise, file, progressHandler));
                  }
                }}
                onRemove={() => {
                  dispatch(FlashcardExerciseActionsCreator.removeFlashcardImage());
                }}
                imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                  loadedExercise,
                  'image',
                  undefined,
                  'learningWordBundle',
                )}
                onProcessingFinished={(url: string) => {
                  dispatch(
                    CommonExerciseActionsCreator.setValueAfterProcessing({
                      url,
                      mediaType: 'image',
                      type: ContentTypes.exercise,
                      field: 'image',
                      language: DEFAULT_LANGUAGE_V2,
                      bundleName: 'learningWordBundle',
                    }),
                  );
                }}
                previewMode={false}
                isForExercise
                errors={mainImageErrors}
                isChangeBlocked={!!isLearningWordBundleChangeBlocked}
                onChangeInstant={() =>
                  setReusedData(loadedExercise.content.learningWordBundle?._id || '', 'image', true)
                }
              />
            </div>

            <div className="exercise-flashcard__wrapper">
              <div className="exercise-flashcard__alternative-container">
                <div className="exercise-flashcard__container">
                  <h3 className="exercise-flashcard__box-title">Flashcard text</h3>
                  <ValidationErrorDisplayer
                    text={
                      learningWordTextErrors.length || learningWordAudioErrors.length
                        ? helpersService.getValidationErrorMessageText(learningWordTextErrors)
                        : helpersService.getValidationErrorMessageText(learningWordPhraseErrors)
                    }
                  />
                </div>

                <TranslationsTipV2
                  visitedBranch={'phrase'}
                  bundleName={'learningWordBundle'}
                  errors={
                    learningWordTextErrors.length || learningWordAudioErrors.length
                      ? learningWordTextErrors
                      : learningWordPhraseErrors
                  }
                  showErrorDisplayer={false}
                >
                  <WritableInputText id="flashcard-text-input" />
                </TranslationsTipV2>

                <AlternativeValueCreator
                  bundleName={'learningWordBundle'}
                  loadSuggestions={loadSuggestions}
                  visitedBranch={'phrase'}
                  onChange={(values: any) => {
                    dispatch(FlashcardExerciseActionsCreator.setLearningWordTextAlt(values, courseLearningLanguage));
                  }}
                  onChangeInstant={() =>
                    isLearningWordBundleChangeBlocked
                      ? setReusedData(loadedExercise.content.learningWordBundle?._id || '', 'phrase', true)
                      : setReusedData(
                          loadedExercise.content.learningWordBundle?.phrase?._id ||
                            loadedExercise.content.learningWordBundle?.phrase?.id ||
                            '',
                          'phrase',
                          false,
                        )
                  }
                  isChangeBlocked={isLearningWordBundleChangeBlocked || isLearningWordChangeBlocked}
                />
              </div>

              <div className="exercise-flashcard__wrapper-double">
                <div className="exercise-flashcard__container">
                  <h3 className="exercise-flashcard__box-title">Audio</h3>
                  <ValidationErrorDisplayer
                    text={helpersService.getValidationErrorMessageText(learningWordAudioErrors)}
                  />
                </div>
                <AudioUpload
                  audioData={ImageUploadUtils.getAudioForFileUpload(
                    loadedExercise,
                    'phrase',
                    courseLearningLanguage,
                    'learningWordBundle',
                  )}
                  onAudioRequestSuccess={(audioRequestId: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                        ...requestOrCancelAudioPayload,
                        audioRequestData: {
                          id: audioRequestId,
                          status: 'new',
                        },
                        field: 'phrase',
                      }),
                    );
                  }}
                  onCancelAudioRequestSuccess={() => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                        ...requestOrCancelAudioPayload,
                        field: 'phrase',
                      }),
                    );
                  }}
                  onChange={(uploadedSound, progressHandler) => {
                    dispatch(
                      AudioUploadActionsCreator.uploadSound(
                        loadedExercise.content.id,
                        ExerciseTypes.flashcard,
                        courseLearningLanguage,
                        loadedExercise.content.learningWordBundle?.phrase?._id,
                        findLocalizationInSearchedLanguage(
                          loadedExercise?.content?.learningWordBundle?.phrase?.audioLocalizations,
                          courseLearningLanguage,
                        ),
                        uploadedSound,
                        'phrase',
                        undefined,
                        undefined,
                        true,
                        'learningWordBundle',
                        progressHandler,
                      ),
                    );
                  }}
                  onProcessingFinished={onProcessingFinishedPhrase}
                  onRemove={() => {
                    dispatch(
                      FlashcardExerciseActionsCreator.removeFlashcardAudio({
                        contentId:
                          loadedExercise.content.learningWordBundle?.phrase?._id ||
                          loadedExercise.content.learningWordBundle?.phrase?.id,
                        language: courseLearningLanguage,
                      }),
                    );
                  }}
                  onChangeInstant={() =>
                    isLearningWordBundleChangeBlocked
                      ? setReusedData(loadedExercise.content.learningWordBundle?._id || '', 'phrase', true)
                      : setReusedData(
                          loadedExercise.content.learningWordBundle?.phrase?._id ||
                            loadedExercise.content.learningWordBundle?.phrase?.id ||
                            '',
                          'phrase',
                          false,
                        )
                  }
                  isChangeBlocked={isLearningWordBundleChangeBlocked || isLearningWordChangeBlocked}
                  fullScreen
                  errors={learningWordAudioErrors}
                  currentLanguage={courseLearningLanguage}
                  fieldName={'phrase'}
                />
              </div>
            </div>

            <ContentToggler
              text="Example"
              open={!!loadedExercise?.content?.learningWordBundle?.example}
              onSwitch={(opened) => {
                if (opened) {
                  dispatch(FlashcardExerciseActionsCreator.setLearningExampleText('', courseLearningLanguage));
                  setFieldValue(
                    `learningWordBundle_example`,
                    values.learningWordBundle_phrase?.map((item: any) => ({ ...item, value: '' })),
                  );
                  setFieldValue(`learningWordBundle_exampleChanged`, true);
                }
              }}
              onRemove={() => {
                if (isLearningWordBundleChangeBlocked) {
                  setReusedData(loadedExercise.content.learningWordBundle?._id || '', 'example', true);
                } else {
                  dispatch(FlashcardExerciseActionsCreator.removeExample());
                  setFieldValue(
                    `learningWordBundle_example`,
                    values.learningWordBundle_example?.map((item: any) => ({ ...item, value: '' })),
                  );
                  setFieldValue(`learningWordBundle_exampleChanged`, true);

                  let updatedErrors = [...errors];

                  availableExampleAudioLocalizations.forEach(({ language, processed, value }) => {
                    if (processed && value) {
                      updatedErrors = removeMediaProcessingValidationError({
                        errors,
                        fieldName: 'learningWordBundle.example',
                        message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
                      });
                    }
                  });

                  if (updatedErrors.length < errors.length) {
                    dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
                  }
                }
              }}
              isChangeBlocked={!!isLearningWordBundleChangeBlocked}
              errors={
                learningExampleTextErrors.length || learningExampleAudioErrors.length
                  ? learningExampleTextErrors
                  : learningExampleErrors
              }
            >
              <TranslationsTipV2
                visitedBranch={'example'}
                bundleName={'learningWordBundle'}
                errors={learningExampleTextErrors}
                showErrorDisplayer={false}
              >
                <WritableInputText id="flashcard-example-input" />
              </TranslationsTipV2>

              <div className="exercise-flashcard__wrapper-double">
                <div className="exercise-flashcard__container">
                  <h3 className="exercise-flashcard__box-title">Example audio</h3>
                  <ValidationErrorDisplayer
                    text={helpersService.getValidationErrorMessageText(learningExampleAudioErrors)}
                  />
                </div>
                <AudioUpload
                  audioData={ImageUploadUtils.getAudioForFileUpload(
                    loadedExercise,
                    'example',
                    courseLearningLanguage,
                    'learningWordBundle',
                  )}
                  onAudioRequestSuccess={(audioRequestId: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                        ...requestOrCancelAudioPayload,
                        audioRequestData: {
                          id: audioRequestId,
                          status: 'new',
                        },
                        field: 'example',
                      }),
                    );
                  }}
                  onCancelAudioRequestSuccess={() => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                        ...requestOrCancelAudioPayload,
                        field: 'example',
                      }),
                    );
                  }}
                  onChange={(uploadedSound, progressHandler) => {
                    dispatch(
                      AudioUploadActionsCreator.uploadSound(
                        loadedExercise.content.id,
                        ExerciseTypes.flashcard,
                        courseLearningLanguage,
                        loadedExercise.content?.learningWordBundle?.example?._id,
                        findLocalizationInSearchedLanguage(
                          loadedExercise?.content?.learningWordBundle?.example?.audioLocalizations,
                          courseLearningLanguage as LanguageV2,
                        ),
                        uploadedSound,
                        'example',
                        undefined,
                        undefined,
                        true,
                        'learningWordBundle',
                        progressHandler,
                      ),
                    );
                  }}
                  onRemove={() => {
                    dispatch(
                      FlashcardExerciseActionsCreator.removeFlashcardExampleAudio({
                        contentId:
                          loadedExercise.content.learningWordBundle?.example?._id ||
                          loadedExercise.content.learningWordBundle?.example?.id,
                        language: courseLearningLanguage,
                      }),
                    );
                  }}
                  onProcessingFinished={onProcessingFinishedExample}
                  onChangeInstant={() =>
                    isLearningWordBundleChangeBlocked
                      ? setReusedData(loadedExercise.content.learningWordBundle?._id || '', 'example', true)
                      : setReusedData(
                          loadedExercise.content.learningWordBundle?.example?._id ||
                            loadedExercise.content.learningWordBundle?.example?.id ||
                            '',
                          'example',
                          false,
                        )
                  }
                  isChangeBlocked={isLearningWordBundleChangeBlocked || isLearningExampleChangeBlocked}
                  fullScreen
                  errors={learningExampleAudioErrors}
                  currentLanguage={courseLearningLanguage}
                  fieldName={'example'}
                />
              </div>
            </ContentToggler>

            <ContentToggler
              text="Video"
              open={!!loadedExercise?.content?.learningWordBundle?.video?._id}
              onRemove={() => {
                dispatch(FlashcardExerciseActionsCreator.removeVideo());
              }}
              errors={mainVideoErrors}
            >
              <VideoUpload
                videoData={ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'learningWordBundle')}
                onChange={(uploadedVideo, progressHandler) => {
                  dispatch(
                    VideoUploadActionsCreator.uploadVideo(
                      loadedExercise,
                      uploadedVideo,
                      'learningWordBundle',
                      progressHandler,
                    ),
                  );
                }}
                onProcessingFinished={(url) => {
                  dispatch(
                    CommonExerciseActionsCreator.setValueAfterProcessing({
                      url,
                      mediaType: 'video',
                      type: ContentTypes.exercise,
                      field: 'video',
                      language: DEFAULT_LANGUAGE_V2,
                      bundleName: 'learningWordBundle',
                    }),
                  );
                }}
                onRemove={() => {
                  if (isLearningWordBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.learningWordBundle?._id || '', 'video', true);
                  } else {
                    dispatch(FlashcardExerciseActionsCreator.removeVideo());
                  }
                }}
                isChangeBlocked={!!isLearningWordBundleChangeBlocked}
                fullScreen
                errors={[]}
                fieldName="video"
                onChangeInstant={() =>
                  setReusedData(loadedExercise.content.learningWordBundle?._id || '', 'video', true)
                }
              />
            </ContentToggler>
          </>
        </ResourceBundleWrapper>
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </div>
    </div>
  );
};

export default Flashcard;
