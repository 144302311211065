import { HighlighterContentBranches } from '@components/Exercises/Highlighter/types/HighlighterContentBranches';
import { UploadMediaResponse } from '@services/HelpersService';
import { AvailableLocalizations, type AvailableLocalizationsType } from '@common/enums/AvailableLocalizations';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { generateDefaultContext } from '@helpers/contextHelper';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import {
  AnyExerciseContentBranch,
  CourseContentBranches,
  ResourceBundleBranches,
  ContentBranches,
  CourseImageBranches,
} from '@common/types/AnyExerciseContentBranch';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { LocalizationTransformer } from '@common/types/LocalizationTransformer';
import { NullifyBundlePayloadArguments, NullifyPayloadArguments } from '@common/types/NullifyPayloadArguments';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { setBundleErrorsToValidation } from '@helpers/updateBundleValidationStatus';
import { createGhostLocalization, findLocalizationInSearchedLanguage } from '@features/content';
import TranslationsPanelUtils from '@components/TranslationsPanel/TranslationsPanelUtils';
import { ALL_LANGUAGES_V2, DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import { clone } from '@helpers/clone';
import * as _ from 'lodash';
import { LexicalItemMatch } from '@features/content/vocabularyReview';

const formatAsLocalization = (
  target: any,
  branchName: string,
  exerciseAndEmptyLocalizationBranchesPayload: any,
): LocalizationInterface => {
  if (Array.isArray(target)) {
    for (let row = 0; row < target.length; row++) {
      for (let column = 0; column < target[0].length; column++) {
        let cell = target[row][column];

        cell.textLocalizations = cell.textLocalizations.map((localization: LocalizationInterface) => {
          const emptyLocalization = createGhostLocalization(localization.language as LanguageV2);

          return {
            ...emptyLocalization,
            ...localization,
          };
        });

        exerciseAndEmptyLocalizationBranchesPayload.content[branchName][row][column].textLocalizations =
          cell.textLocalizations;
      }
    }

    return exerciseAndEmptyLocalizationBranchesPayload;
  } else {
    let cell = target;

    let emptyLocalizations = ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
      'emptyLocalizations',
    ]).emptyLocalizations;

    cell.textLocalizations = emptyLocalizations.map((emptyLocalization: LocalizationInterface) => {
      let foundLocalization: LocalizationInterface | undefined = findLocalizationInSearchedLanguage(
        cell.textLocalizations,
        emptyLocalization.language as LanguageV2,
      );

      let output;

      if (foundLocalization === undefined) {
        output = {
          ...emptyLocalization,
        };
      } else {
        output = {
          ...emptyLocalization,
          ...foundLocalization,
        };
      }

      return output;
    });
    cell.audioLocalizations = emptyLocalizations.map((emptyLocalization: LocalizationInterface) => {
      let foundLocalization: LocalizationInterface | undefined = findLocalizationInSearchedLanguage(
        cell.audioLocalizations,
        emptyLocalization.language as LanguageV2,
      );

      let output;

      if (foundLocalization === undefined) {
        output = {
          ...emptyLocalization,
        };
      } else {
        output = {
          ...emptyLocalization,
          ...foundLocalization,
        };
      }

      return output;
    });

    exerciseAndEmptyLocalizationBranchesPayload.content[branchName].textLocalizations = cell.textLocalizations;
    exerciseAndEmptyLocalizationBranchesPayload.content[branchName].audioLocalizations = cell.audioLocalizations;

    return exerciseAndEmptyLocalizationBranchesPayload;
  }
};

const ExerciseDataModelAssembler = {
  ensureFieldIsReadyForUse(
    exercise: AnyExerciseInterface,
    output: any,
    fields: AnyExerciseContentBranch[],
    exerciseAndEmptyLocalizationBranchesPayload: any,
  ) {
    const otherBundleNames = [
      'distractor1',
      'distractor2',
      'fixedItem1',
      'matchingItem1',
      'fixedItem2',
      'matchingItem2',
      'fixedItem3',
      'matchingItem3',
    ];

    try {
      fields.forEach((field: AnyExerciseContentBranch) => {
        const defaultDescription = generateDefaultContext(exercise.content.type, field, true);
        let fieldValue = exercise?.content?.[field];

        if (fieldValue === null) {
          if (field.includes('Bundle') || otherBundleNames.includes(field)) {
            output.content[field] = {
              phrase:
                ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(
                  defaultDescription,
                ),
              example: null,
              image: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
              video: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
              mappings: exercise?.content?.[field]?.mappings,
              isVocabulary: false,
            };
          } else {
            output.content[field] =
              ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(
                defaultDescription,
              );
          }
        } else {
          // Process bundle related field
          if (field.includes('Bundle') || otherBundleNames.includes(field)) {
            output.content[field] = {
              _id: exercise?.content?.[field]._id,
              phrase:
                exercise?.content?.[field].phrase?.textLocalizations?.length ||
                exercise?.content?.[field].phrase?.audioLocalizations?.length
                  ? {
                      ...exercise?.content?.[field].phrase,
                      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                        ['textLocalizations'],
                        exercise?.content?.[field].phrase?.textLocalizations,
                      ),
                      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                        ['audioLocalizations'],
                        exercise?.content?.[field].phrase?.audioLocalizations,
                      ),
                      description: exercise?.content?.[field]?.phrase?.description || defaultDescription,
                    }
                  : {
                      ...exercise?.content?.[field].phrase,
                      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                        'textLocalizations',
                        'audioLocalizations',
                      ]),
                      description: exercise?.content?.[field]?.phrase?.description || defaultDescription,
                    },
              example:
                exercise?.content?.[field].example?.textLocalizations?.length ||
                exercise?.content?.[field].example?.audioLocalizations?.length
                  ? {
                      ...exercise?.content?.[field].example,
                      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                        ['textLocalizations'],
                        exercise?.content?.[field].example?.textLocalizations,
                      ),
                      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                        ['audioLocalizations'],
                        exercise?.content?.[field].example?.audioLocalizations,
                      ),
                      description:
                        exercise?.content?.[field]?.example?.description ||
                        generateDefaultContext(exercise.content.type, field, false),
                    }
                  : {
                      ...exercise?.content?.[field].example,
                      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                        'textLocalizations',
                        'audioLocalizations',
                      ]),
                      description:
                        exercise?.content?.[field]?.example?.description ||
                        generateDefaultContext(exercise.content.type, field, false),
                    },
              image:
                exercise?.content?.[field].image ||
                ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
              video:
                exercise?.content?.[field].video ||
                ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
              isVocabulary: exercise?.content?.[field].isVocabulary || false,
              mappings: exercise?.content?.[field]?.mappings,
              validationStatus: exercise?.content?.[field]?.validationStatus
                ? {
                    errors: exercise?.content?.[field].validationStatus.errors.map(
                      (error: ValidationErrorInterface) => ({
                        ...error,
                        message: `${_.upperFirst(error.field)}: ${error.message}`,
                      }),
                    ),
                    exerciseRelatedErrors: exercise?.content?.[field].validationStatus.exerciseRelatedErrors,
                  }
                : null,
            };
            output.content.validation = {
              ...output.content.validation,
              errors: exercise?.content?.[field].validationStatus.errors
                ? setBundleErrorsToValidation(
                    field,
                    output.content.validation.errors,
                    exercise?.content?.[field].validationStatus.errors,
                  )
                : [
                    ...output.content.validation.errors,
                    ...exerciseAndEmptyLocalizationBranchesPayload.content.validation.errors,
                  ],
              valid:
                output.content.validation.valid &&
                !exercise?.content?.[field].validationStatus.errors.length &&
                exerciseAndEmptyLocalizationBranchesPayload.content.validation.valid,
            };
          } else if (field === 'lexicalItems' && exercise.content[field].length) {
            // Process lexicalItems field if populated only
            const hasInvalidLexicalItems = exercise.content[field].some(
              (lexicalItem: LexicalItemMatch) => !lexicalItem.validationStatus.valid,
            );

            output.content.validation = {
              ...output.content.validation,
              valid:
                output.content.validation.valid &&
                !hasInvalidLexicalItems &&
                exerciseAndEmptyLocalizationBranchesPayload.content.validation.valid,
            };
          } else {
            output = formatAsLocalization(exerciseAndEmptyLocalizationBranchesPayload.content[field], field, output);
          }
        }
      });

      return output;
    } catch (e: any) {
      throw new Error(`Error when processing ${fields} at ensureFieldIsReadyForUse: ${e}`);
    }
  },
  /**
   * @modifies localizations
   */
  fillAbsentLocalizations(localizations: LocalizationInterface[]): void {
    const missingLanguages = ALL_LANGUAGES_V2.filter(
      (language) => !localizations.find((localization) => localization.language === language),
    );

    missingLanguages.forEach((language) => {
      localizations.push(createGhostLocalization(language));
    });
  },
  prepareEmptyLocalizationBranches(branches: string[]) {
    const output: any = {};

    branches.forEach((branch) => {
      output[branch] = ALL_LANGUAGES_V2.map((language) => {
        return createGhostLocalization(language);
      });
    });

    return output;
  },
  prepareEmptyLocalizationBranchesForSomeLanguages(branches: string[], existingLanguages: any[]) {
    let output: any = {};

    branches.forEach((branch) => {
      output[branch] = ALL_LANGUAGES_V2.map((language) => {
        if (
          existingLanguages
            .filter((lang) => lang.language)
            .map((lang) => lang.language)
            .includes(language)
        ) {
          return existingLanguages.find((lang) => lang.language === language);
        } else {
          return createGhostLocalization(language);
        }
      });
    });

    return output;
  },
  generateTranslationsPanelContentPopulatedWithEmptyLocalizations(defaultDescription?: string) {
    let translationsPanelContent: TranslationsPanelContentInterface =
      ExerciseDataModelAssembler._generateTranslationsPanelContent('dummy', 'dummy', null, true);

    let {
      specifiedLocationsBranchInTranslationsPanel,
    }: {
      specifiedLocationsBranchInTranslationsPanel: LocalizationInterface[];
    } = ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['specifiedLocationsBranchInTranslationsPanel']);

    translationsPanelContent.textLocalizations = [...clone(specifiedLocationsBranchInTranslationsPanel)];
    translationsPanelContent.audioLocalizations = [...clone(specifiedLocationsBranchInTranslationsPanel)];
    translationsPanelContent.videoLocalizations = [...clone(specifiedLocationsBranchInTranslationsPanel)];
    translationsPanelContent.imageLocalizations = [...clone(specifiedLocationsBranchInTranslationsPanel)];

    translationsPanelContent.description = defaultDescription || '';

    return translationsPanelContent;
  },
  _generateTranslationsPanelContent(
    otherLocations: any,
    localizationBranch: any,
    emptyLocalizationBranches: any,
    forceGeneration: boolean = false,
  ) {
    let translationsPanelContent: TranslationsPanelContentInterface;

    if (forceGeneration) {
      translationsPanelContent = TranslationsPanelUtils.createEmptyTranslationsPanelContent();
    } else {
      translationsPanelContent = otherLocations.content[localizationBranch as any];

      if (translationsPanelContent === null) {
        translationsPanelContent = TranslationsPanelUtils.createEmptyTranslationsPanelContent();
      }
    }

    let specifiedLocationsBranchInTranslationsPanel: LocalizationInterface[];

    [
      AvailableLocalizations.text,
      AvailableLocalizations.audio,
      AvailableLocalizations.video,
      AvailableLocalizations.image,
    ].forEach((availableLocalizationType: string) => {
      switch (availableLocalizationType) {
        case AvailableLocalizations.text:
          specifiedLocationsBranchInTranslationsPanel = translationsPanelContent.textLocalizations;
          break;

        case AvailableLocalizations.audio:
          specifiedLocationsBranchInTranslationsPanel = translationsPanelContent.audioLocalizations;
          break;

        case AvailableLocalizations.video:
          specifiedLocationsBranchInTranslationsPanel = translationsPanelContent.videoLocalizations;
          break;

        case AvailableLocalizations.image:
          specifiedLocationsBranchInTranslationsPanel = translationsPanelContent.imageLocalizations;
          break;
      }

      if (!forceGeneration) {
        let mergeLocalizations: any[] = [];

        emptyLocalizationBranches[localizationBranch].forEach((emptyLocalization: LocalizationInterface) => {
          let existingLocalization = specifiedLocationsBranchInTranslationsPanel.find(
            (filledLocalization: LocalizationInterface) => filledLocalization.language === emptyLocalization.language,
          );

          if (existingLocalization === undefined) {
            mergeLocalizations.push(emptyLocalization);
          } else {
            mergeLocalizations.push(existingLocalization);
          }
        });

        specifiedLocationsBranchInTranslationsPanel = mergeLocalizations;
      }
    });

    return translationsPanelContent;
  },
  mergeExistingTranslationsPanelContentInterface(
    existingTranslationsPanelContent: TranslationsPanelContentInterface,
    emptyTranslationsPanelContent: TranslationsPanelContentInterface,
  ) {
    ['textLocalizations', 'audioLocalizations', 'videoLocalizations', 'imageLocalizations'].forEach(
      (availableLocalizationType: string) => {
        let mergedLocalizations: LocalizationInterface[] = [];
        existingTranslationsPanelContent = clone(existingTranslationsPanelContent);

        (existingTranslationsPanelContent as any)[availableLocalizationType].forEach(
          (existingLocalization: LocalizationInterface) => {
            let ghostLocalization: LocalizationInterface = (emptyTranslationsPanelContent as any)[
              availableLocalizationType
            ].find(
              (ghostLocalization: LocalizationInterface) =>
                existingLocalization.language === ghostLocalization.language,
            );

            let merger: LocalizationInterface = {
              ...ghostLocalization,
              ...existingLocalization,
            };
            mergedLocalizations.push(merger);
          },
        );

        (existingTranslationsPanelContent as any)[availableLocalizationType] = mergedLocalizations;
      },
    );

    return existingTranslationsPanelContent;
  },
  findFieldValueInLanguageOfExercise(
    field: HighlighterContentBranches,
    loadedExercise: AnyExerciseInterface,
    languageForTipsValue: string,
    bundleName?: string,
  ): string {
    let fieldInContent = bundleName ? loadedExercise.content[bundleName][field] : loadedExercise.content[field];

    if (fieldInContent?.textLocalizations === undefined) return '';
    if (fieldInContent?.textLocalizations.length === 0) return '';

    let output = (
      findLocalizationInSearchedLanguage(
        fieldInContent.textLocalizations,
        languageForTipsValue as LanguageV2,
      ) as LocalizationInterface
    ).value;

    return output;
  },
  findFieldValueInLanguageCommon(
    field: AnyExerciseContentBranch | CourseContentBranches,
    content: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {},
    languageForTipsValue: string,
    bundleName?: string,
  ): string {
    let fieldInContent = bundleName ? (content as any)[bundleName]?.[field] : (content as any)[field];

    if (fieldInContent?.textLocalizations === undefined) return '';
    if (fieldInContent?.textLocalizations.length === 0) return '';

    let output = (
      findLocalizationInSearchedLanguage(
        fieldInContent.textLocalizations,
        languageForTipsValue as LanguageV2,
      ) as LocalizationInterface
    )?.value;

    return output;
  },
  generateBasicImagePayload(
    mediaURL: UploadMediaResponse['mediaURL'],
    learningLanguage: LanguageV2,
  ): LocalizationInterface {
    const output: LocalizationInterface = {
      language: learningLanguage,
      type: 'image',
      value: mediaURL,
      _id: '',
      processed: false,
    };

    return output;
  },
  updateTextFieldInBundle(
    field: ResourceBundleBranches,
    bundleName: string,
    state: CourseSliceInterface,
    payload: LocalizationTransformer,
    localizationType: AvailableLocalizationsType,
    defaultDescription?: string,
  ): CourseSliceInterface {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise as AnyLoadedExercise;

    let { content } = loadedExercise.exercise;

    if (content[bundleName][field] === null) {
      content[bundleName][field] =
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(defaultDescription);
    }

    const localizationTypeBranch = ExerciseDataModelAssembler.getLocalizationTypeBranch(localizationType);

    const localization = findLocalizationInSearchedLanguage(
      (loadedExercise.exercise as any).content[bundleName][field][localizationTypeBranch],
      payload.language as LanguageV2,
    ) as LocalizationInterface;

    (loadedExercise.exercise as any).content[bundleName][field].changed = true;
    localization.value = payload.value;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  updateAltTextField(
    field: AnyExerciseContentBranch,
    state: CourseSliceInterface,
    payload: any,
    localizationType: AvailableLocalizationsType,
    bundleName?: string,
  ): CourseSliceInterface {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise as AnyLoadedExercise;

    let { content } = loadedExercise.exercise;

    if (bundleName) {
      if (content[bundleName][field] === null) {
        content[bundleName][field] =
          ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
      }

      const localizationTypeBranch = ExerciseDataModelAssembler.getLocalizationTypeBranch(localizationType);

      const localization = findLocalizationInSearchedLanguage(
        (loadedExercise.exercise as any).content[bundleName][field][localizationTypeBranch],
        payload.language as LanguageV2,
      ) as LocalizationInterface;

      (loadedExercise.exercise as any).content[bundleName][field].changed = true;

      localization.alternativeValues = [...payload.values.map((val: any) => val.value)];

      return {
        ...clonedState,
        loadedExercise,
      };
    } else {
      if (content[field] === null) {
        content[field] = ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
      }

      const localizationTypeBranch = ExerciseDataModelAssembler.getLocalizationTypeBranch(localizationType);

      const localization = findLocalizationInSearchedLanguage(
        (loadedExercise.exercise as any).content[field][localizationTypeBranch],
        payload.language as LanguageV2,
      ) as LocalizationInterface;

      (loadedExercise.exercise as any).content[field].changed = true;

      localization.alternativeValues = [...payload.values.map((val: any) => val.value)];

      return {
        ...clonedState,
        loadedExercise,
      };
    }
  },
  getLocalizationTypeBranch(localizationType: AvailableLocalizationsType) {
    switch (localizationType) {
      case AvailableLocalizations.text:
        return 'textLocalizations';

      case AvailableLocalizations.image:
        return 'imageLocalizations';

      case AvailableLocalizations.audio:
        return 'audioLocalizations';

      case AvailableLocalizations.video:
        return 'videoLocalizations';

      default: {
        return 'textLocalizations';
      }
    }
  },
  getCorrectFieldName(
    branch: ContentBranches | CourseImageBranches,
  ): ContentBranches | CourseImageBranches | 'title' | 'description' {
    if (branch === 'titleWithLocalizations') {
      return 'title';
    } else if (branch === 'descriptionWithLocalizations') {
      return 'description';
    } else {
      return branch;
    }
  },
  updateImageField(field: any, state: CourseSliceInterface, payload: LocalizationInterface): CourseSliceInterface {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise as AnyLoadedExercise;

    if (loadedExercise.exercise.content[field] === null) {
      loadedExercise.exercise.content[field] =
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
    } else if (loadedExercise.exercise.content[field].imageLocalizations.length === 0) {
      loadedExercise.exercise.content[field].imageLocalizations =
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations().imageLocalizations;
    }

    const imageLocalizations = loadedExercise.exercise.content[field].imageLocalizations;

    const localization = findLocalizationInSearchedLanguage(
      imageLocalizations,
      DEFAULT_LANGUAGE_V2,
    ) as LocalizationInterface;

    localization.value = payload.value;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  updateCheckbox(field: any, state: CourseSliceInterface, payload: DisplayContentToUserModeType): CourseSliceInterface {
    let clonedState: CourseSliceInterface = clone(state);
    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise;

    if (loadedExercise.exercise.content !== undefined) {
      loadedExercise.exercise.content[field] = payload[field];
      loadedExercise.exercise.content[`${field}Changed`] = true;
    }

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  updateCheckboxWithoutAffectingSaveButton(
    field: any,
    state: CourseSliceInterface,
    payload: DisplayContentToUserModeType,
  ): CourseSliceInterface {
    let clonedState: CourseSliceInterface = clone(state);
    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise;

    if (loadedExercise.exercise.content !== undefined) {
      loadedExercise.exercise.content[field] = payload[field];
    }

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  nullifyField(payload: NullifyPayloadArguments & { state: CourseSliceInterface }): CourseSliceInterface {
    let { field, state, arrayRowPosition, arrayColumnPosition, nullifyOnlyThisLocalizationType } = payload;

    let clonedState: CourseSliceInterface = clone(state);
    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise;

    if ((loadedExercise.exercise as AnyExerciseInterface)?.content !== undefined) {
      if (arrayRowPosition !== undefined) {
        if (loadedExercise.exercise.content[field] != null) {
          if (arrayColumnPosition === undefined) {
            if (nullifyOnlyThisLocalizationType === undefined) {
              loadedExercise.exercise.content[field][arrayRowPosition] = null;
              loadedExercise.exercise.content[`${field}Changed`] = true;
            } else {
              loadedExercise.exercise.content[field][arrayRowPosition][nullifyOnlyThisLocalizationType] = [];
            }
          } else {
            if (nullifyOnlyThisLocalizationType === undefined) {
              loadedExercise.exercise.content[field][arrayRowPosition][arrayColumnPosition] = null;
            } else {
              loadedExercise.exercise.content[field][arrayRowPosition][arrayColumnPosition][
                nullifyOnlyThisLocalizationType
              ] = [];
            }
          }
        }
      } else {
        if (nullifyOnlyThisLocalizationType === undefined) {
          loadedExercise.exercise.content[field] = null;
          loadedExercise.exercise.content[`${field}Changed`] = true;
        } else {
          loadedExercise.exercise.content[field][nullifyOnlyThisLocalizationType] = [];
        }
      }
    }

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  nullifyFieldInBundle(payload: NullifyBundlePayloadArguments & { state: CourseSliceInterface }): CourseSliceInterface {
    let { field, bundleName, state, arrayRowPosition, arrayColumnPosition, nullifyOnlyThisLocalizationType } = payload;

    let clonedState: CourseSliceInterface = clone(state);
    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise;

    if ((loadedExercise.exercise as AnyExerciseInterface)?.content !== undefined) {
      if (arrayRowPosition !== undefined) {
        if (loadedExercise.exercise.content[bundleName][field] != null) {
          if (arrayColumnPosition === undefined) {
            if (nullifyOnlyThisLocalizationType === undefined) {
              loadedExercise.exercise.content[bundleName][field][arrayRowPosition] = null;
              loadedExercise.exercise.content[bundleName][`${field}Changed`] = true;
            } else {
              loadedExercise.exercise.content[bundleName][field][arrayRowPosition][nullifyOnlyThisLocalizationType] =
                [];
            }
          } else {
            if (nullifyOnlyThisLocalizationType === undefined) {
              loadedExercise.exercise.content[bundleName][field][arrayRowPosition][arrayColumnPosition] = null;
            } else {
              loadedExercise.exercise.content[bundleName][field][arrayRowPosition][arrayColumnPosition][
                nullifyOnlyThisLocalizationType
              ] = [];
            }
          }
        }
      } else {
        if (nullifyOnlyThisLocalizationType === undefined) {
          loadedExercise.exercise.content[bundleName][field] = null;
          loadedExercise.exercise.content[bundleName][`${field}Changed`] = true;
        } else {
          loadedExercise.exercise.content[bundleName][field][nullifyOnlyThisLocalizationType] = [];
        }
      }
    }

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  updateTrueFalse(field: AnyExerciseContentBranch, state: CourseSliceInterface, isTrue: boolean): CourseSliceInterface {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise as AnyLoadedExercise;

    if ((loadedExercise.exercise as AnyExerciseInterface).content !== undefined) {
      (loadedExercise.exercise as AnyExerciseInterface).content[field] = isTrue;
    }

    (loadedExercise.exercise as AnyExerciseInterface).content[`${field}Changed`] = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  updateNumberField(
    field: AnyExerciseContentBranch,
    state: CourseSliceInterface,
    newValue: number,
  ): CourseSliceInterface {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise as AnyLoadedExercise;

    if ((loadedExercise.exercise as AnyExerciseInterface).content !== undefined) {
      (loadedExercise.exercise as AnyExerciseInterface).content[field] = newValue;
      (loadedExercise.exercise as AnyExerciseInterface).content[`${field}Changed`] = true;
    }

    return {
      ...clonedState,
      loadedExercise,
    };
  },
};

export default ExerciseDataModelAssembler;
