import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import { ContentTypes } from '@common/enums/ContentTypes';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { StructureItemInterface } from '@common/interfaces/navigation/StructureInterface';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { SearchPanelSliceType, SearchService } from '@features/search';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import ExercisesService from '@services/ExercisesService';
import { SearchActions } from '@actions/SearchActions';
import NavigationMenuSagas from '@sagas/navigationMenu/definitions/NavigationMenuSagasDefinition';
import { addToast } from '@features/app/toast';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { selectSearchPanel, selectSearchPanelColumn, selectSearchPanelRow } from '@selectors/UiSelectors';
import { selectCourse, selectCourseStructure, selectLoadedExerciseData } from '@selectors/CoursesSelectors';
import { RoleplayActions } from '@actions/RoleplayActions';

const SearchV2Sagas = {
  *useString({ payload }: PayloadAction<any>) {
    try {
      const searchParams: SearchPanelSliceType = yield select(selectSearchPanel);
      const row: number = yield select(selectSearchPanelRow);
      const column: number = yield select(selectSearchPanelColumn);

      const { contentType, visitedBranch, bundleName } = searchParams;

      if (contentType === ContentTypes.grammarCategory || contentType === ContentTypes.grammarTopic) {
        yield put({
          type: GrammarReviewActions.SET_STRING,
          payload: { newString: payload, contentType, fieldName: visitedBranch, isReuse: true },
        });
      } else if (contentType === ContentTypes.roleplayScenario) {
        yield put({
          type: RoleplayActions.SET_STRING,
          payload: { newString: payload, contentType, fieldName: visitedBranch, isReuse: true },
        });
      } else {
        yield put({
          type: SearchActions.USE_STRING_SUCCEEDED,
          payload: { newString: payload, contentType, visitedBranch, row, column, isReuse: true, bundleName },
        });
      }

      yield put(SearchModalActionsCreator.hideSearchV2());
    } catch (e: any) {
      console.error(e);
    }
  },
  *copyString({ payload }: PayloadAction<any>) {
    try {
      const options: SearchPanelSliceType = yield select(selectSearchPanel);
      const row: number = yield select(selectSearchPanelRow);
      const column: number = yield select(selectSearchPanelColumn);

      const { contentType, visitedBranch, bundleName } = options;

      const cloneResult: Awaited<ReturnType<typeof SearchService.cloneContent>> = yield call(
        SearchService.cloneContent,
        payload.id,
      );
      if (cloneResult.status === 200) {
        const finalNewString = {
          ...payload,
          _id: cloneResult.data.id,
          id: cloneResult.data.id,
        };

        if (contentType === ContentTypes.grammarCategory || contentType === ContentTypes.grammarTopic) {
          yield put({
            type: GrammarReviewActions.SET_STRING,
            payload: { newString: finalNewString, contentType, fieldName: visitedBranch, isCopied: true },
          });
        } else if (contentType === ContentTypes.roleplayScenario) {
          yield put({
            type: RoleplayActions.SET_STRING,
            payload: { newString: payload, contentType, fieldName: visitedBranch, isReuse: true },
          });
        } else {
          yield put({
            type: SearchActions.USE_STRING_SUCCEEDED,
            payload: { newString: finalNewString, contentType, visitedBranch, row, column, isCopied: true, bundleName },
          });
        }

        yield put(SearchModalActionsCreator.hideSearchV2());
      }
    } catch (e: any) {
      console.error(e);
    }
  },
  *useBundle({ payload }: PayloadAction<any>) {
    try {
      const { id, bundleName } = payload;

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: id,
      });

      const exercise: AnyExerciseInterface = yield select(selectLoadedExerciseData);

      const loadedCourse: CourseInterface = yield select(selectCourse);

      const saveResult: Awaited<ReturnType<typeof ExercisesService.misc.updateExercise>> = yield call(
        ExercisesService.misc.updateExercise,
        { [bundleName]: id },
        exercise.content.id,
        exercise.content.type,
      );

      if (saveResult?.status === 204) {
        yield put(SearchModalActionsCreator.hideSearchV2());

        addToast({
          type: 'success',
          title: 'Resource bundle was reused',
        });
      }

      yield call(ExerciseSagas.requestOneExercise as any, {
        payload: {
          exerciseId: exercise.content.id,
          courseId: loadedCourse.id,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *copyBundle({ payload }: PayloadAction<any>) {
    try {
      const { id, bundleName } = payload;

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: id,
      });
      const cloneResult: Awaited<ReturnType<typeof SearchService.cloneBundle>> = yield call(
        SearchService.cloneBundle,
        id,
      );

      if (cloneResult.status === 200) {
        const exercise: AnyExerciseInterface = yield select(selectLoadedExerciseData);

        const loadedCourse: CourseInterface = yield select(selectCourse);

        const saveResult: Awaited<ReturnType<typeof ExercisesService.misc.updateExercise>> = yield call(
          ExercisesService.misc.updateExercise,
          { [bundleName]: cloneResult.data.id },
          exercise.content.id,
          exercise.content.type,
        );

        if (saveResult?.status === 204) {
          yield put(SearchModalActionsCreator.hideSearchV2());

          addToast({
            type: 'success',
            title: 'Resource bundle was copied',
          });
        }

        yield call(ExerciseSagas.requestOneExercise as any, {
          payload: {
            exerciseId: exercise.content.id,
            courseId: loadedCourse.id,
          },
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  },
  *useExercise({ payload }: PayloadAction<any>) {
    try {
      const structure: StructureItemInterface[] = yield select(selectCourseStructure);
      const { id, parentId, position } = payload;

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: id,
      });

      const reuseResult: Awaited<ReturnType<typeof SearchService.useExercise>> = yield call(
        SearchService.useExercise,
        id,
        parentId,
        position,
      );

      if (reuseResult.status === 204) {
        yield put(SearchModalActionsCreator.hideSearchV2());

        addToast({
          type: 'success',
          title: 'Exercise was reused',
        });

        const lessonId = structure?.find((item) => item.id === parentId)?.parentId;

        if (lessonId) {
          yield put(CourseEditionActionsCreator.getGroup(parentId, lessonId));

          yield call(NavigationMenuSagas.updateNavigation, parentId, ContentTypes.activity);
        }
      }
    } catch (e: any) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });
      console.error(e);
    }
  },
  *copyExercise({ payload }: PayloadAction<any>) {
    try {
      const structure: StructureItemInterface[] = yield select(selectCourseStructure);
      const { id, parentId, position } = payload;

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: id,
      });

      const cloneResult: Awaited<ReturnType<typeof SearchService.cloneExercise>> = yield call(
        SearchService.cloneExercise,
        id,
      );

      if (cloneResult.status === 200) {
        const reuseResult: Awaited<ReturnType<typeof SearchService.useExercise>> = yield call(
          SearchService.useExercise,
          cloneResult.data.id,
          parentId,
          position,
        );

        if (reuseResult.status === 204) {
          yield put(SearchModalActionsCreator.hideSearchV2());

          addToast({
            type: 'success',
            title: 'Exercise was copied',
          });

          const lessonId = structure?.find((item) => item.id === parentId)?.parentId;

          if (lessonId) {
            yield put(CourseEditionActionsCreator.getGroup(parentId, lessonId));

            yield call(NavigationMenuSagas.updateNavigation, parentId, ContentTypes.activity);
          }
        }
      }
    } catch (e: any) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });
      console.error(e);
    }
  },
  *useLesson({ payload }: PayloadAction<any>) {
    try {
      const { id, parentId, position } = payload;

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: id,
      });

      const reuseResult: Awaited<ReturnType<typeof SearchService.useGroup>> = yield call(
        SearchService.useGroup,
        id,
        parentId,
        position,
      );

      const loadedCourse: CourseInterface = yield select(selectCourse);

      if (reuseResult.status === 204) {
        yield put(SearchModalActionsCreator.hideSearchV2());

        addToast({
          type: 'success',
          title: 'Lesson was reused',
        });

        yield put(CourseEditionActionsCreator.getGroup(parentId, loadedCourse.id));

        yield call(NavigationMenuSagas.updateNavigation, parentId, ContentTypes.chapter);
      }
    } catch (e: any) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });
      console.error(e);
    }
  },
  *copyLesson({ payload }: PayloadAction<any>) {
    try {
      const loadedCourse: CourseInterface = yield select(selectCourse);
      const { id, parentId, position } = payload;

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: id,
      });

      const cloneResult: Awaited<ReturnType<typeof SearchService.cloneGroup>> = yield call(
        SearchService.cloneGroup,
        id,
      );

      if (cloneResult.status === 200) {
        const reuseResult: Awaited<ReturnType<typeof SearchService.useGroup>> = yield call(
          SearchService.useGroup,
          cloneResult.data.id,
          parentId,
          position,
        );

        if (reuseResult.status === 204) {
          yield put(SearchModalActionsCreator.hideSearchV2());

          addToast({
            type: 'success',
            title: 'Lesson was copied',
          });

          yield put(CourseEditionActionsCreator.getGroup(parentId, loadedCourse.id));

          yield call(NavigationMenuSagas.updateNavigation, parentId, ContentTypes.chapter);
        }
      }
    } catch (e: any) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });
      console.error(e);
    }
  },
  *useActivity({ payload }: PayloadAction<any>) {
    try {
      const structure: StructureItemInterface[] = yield select(selectCourseStructure);
      const { id, parentId, position } = payload;

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: id,
      });

      const reuseResult: Awaited<ReturnType<typeof SearchService.useGroup>> = yield call(
        SearchService.useGroup,
        id,
        parentId,
        position,
      );

      if (reuseResult.status === 204) {
        yield put(SearchModalActionsCreator.hideSearchV2());

        addToast({
          type: 'success',
          title: 'Activity was reused',
        });

        const levelId = structure?.find((item) => item.id === parentId)?.parentId;

        if (levelId) {
          yield put(CourseEditionActionsCreator.getGroup(parentId, levelId));

          yield call(NavigationMenuSagas.updateNavigation, parentId, ContentTypes.lesson);
        }
      }
    } catch (e: any) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });
      console.error(e);
    }
  },
  *copyActivity({ payload }: PayloadAction<any>) {
    try {
      const structure: StructureItemInterface[] = yield select(selectCourseStructure);
      const { id, parentId, position } = payload;

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: id,
      });

      const cloneResult: Awaited<ReturnType<typeof SearchService.cloneGroup>> = yield call(
        SearchService.cloneGroup,
        id,
      );

      if (cloneResult.status === 200) {
        const reuseResult: Awaited<ReturnType<typeof SearchService.useGroup>> = yield call(
          SearchService.useGroup,
          cloneResult.data.id,
          parentId,
          position,
        );

        if (reuseResult.status === 204) {
          yield put(SearchModalActionsCreator.hideSearchV2());

          addToast({
            type: 'success',
            title: 'Activity was copied',
          });

          const levelId = structure?.find((item) => item.id === parentId)?.parentId;

          if (levelId) {
            yield put(CourseEditionActionsCreator.getGroup(parentId, levelId));

            yield call(NavigationMenuSagas.updateNavigation, parentId, ContentTypes.lesson);
          }
        }
      }
    } catch (e: any) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });
      console.error(e);
    }
  },
};

export default SearchV2Sagas;
