import { LanguageV2 } from '@features/content/languages';
import { VocabularyReviewListItem } from '@features/content/vocabularyReview';

import { LexicalItemResultCard } from './SearchResultItemCard';
import { DBId } from '@common/types/DBId';
import { OnMatchCallback } from '@features/content/exercises';

type LexicalItemSearchResultItemProps = {
  content: VocabularyReviewListItem;
  language: LanguageV2;
  onClick?: (lexicalItemId: DBId) => void;
  onMatch?: OnMatchCallback;
};

export const LexicalItemSearchResultItem = ({
  content,
  language,
  onClick,
  onMatch,
}: LexicalItemSearchResultItemProps) => {
  return (
    <>
      <LexicalItemResultCard
        content={content}
        language={language}
        onClick={onClick}
        onMatch={({ lexicalItemId, phrase, ready }) => onMatch && onMatch({ lexicalItemId, phrase, ready })}
      />
    </>
  );
};
