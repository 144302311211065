import { ReactComponent as RemoveIcon } from '@static/svg/remove.svg';

import { ValuePillContainer } from './styles';

type ValuePillProps = {
  errored?: boolean;
  isToBeRemoved?: boolean;
  label: string;
  onClick?: () => void;
  onRemove?: () => void;
};

export const ValuePill = ({ errored = false, isToBeRemoved = false, label, onClick, onRemove }: ValuePillProps) => {
  return (
    <ValuePillContainer errored={errored} isToBeRemoved={isToBeRemoved}>
      <span onClick={onClick}>{label}</span>
      {onRemove && <RemoveIcon onClick={onRemove} />}
    </ValuePillContainer>
  );
};
