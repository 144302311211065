import styled from 'styled-components/macro';

import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';

export const LexicalItemsMatchedWrapper = styled.div`
  position: relative;
`;

export const StyledValidationErrorDisplayer = styled(ValidationErrorDisplayer)`
  margin: 0;
  position: absolute;
  right: 0;
  top: -0.4rem;
  z-index: 1;
`;
