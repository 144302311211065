import { ContentTypes } from '@common/enums/ContentTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { WritableInputText } from '@components/WritableInputText';
import type { LanguageV2 } from '@features/content/languages';
import { TranslationsTipWrapper } from '@features/content/translations';
import helpersService from '@services/HelpersService';

import {
  RoleplayScenarioContentType,
  RoleplayScenarioFieldWithLocalizationNames,
  RoleplayScenarioFormikValues,
} from '../../types';
import { useRoleplay } from '../../useRoleplay';
import { FieldGroupHeader, FieldLabel, StyledCol, StyledRow } from '../styles';
import { PHRASEBOOK_PHRASE_MAX_CHARS } from '../constants';
import { DBId } from '@common/types/DBId';

export const Phrasebook = ({ content, language }: { content: RoleplayScenarioContentType; language: LanguageV2 }) => {
  const { interfaceLanguages, onContentSuggestionsClick, onOpenTranslationPanel, onRemoveString } = useRoleplay();

  const phraseBookFieldNameRegExp = /^phraseBookPhrase[\d]$/;
  const phrasebook = Object.entries(content).filter(([key]) => phraseBookFieldNameRegExp.test(key));

  const { errors } = content?.validationStatus || { errors: [] };
  const phraseBookErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'phrasebook');

  return (
    <>
      <FieldGroupHeader level={1} spaceBottom="S" spaceTop="L" variant="subheader">
        Phrasebook
      </FieldGroupHeader>
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(phraseBookErrors)} />

      {phrasebook.map((phrase, index) => {
        const phraseContent = phrase[1] as TranslationsPanelContentInterface & { id?: DBId };
        const phraseFieldName = phrase[0] as RoleplayScenarioFieldWithLocalizationNames;
        const phraseDisplayIndex = index + 1;
        const phraseBookPhraseErrorField = `phrasebook[${index}]`;
        const phraseBookPhraseErrors = errors.filter(
          (error: ValidationErrorInterface) => error.field === phraseBookPhraseErrorField,
        );

        if (!phraseContent.id) {
          phraseBookPhraseErrors.push({
            field: phraseBookPhraseErrorField,
            isWarning: false,
            message: 'This value should not be blank.',
          });
        }

        return (
          <StyledRow>
            <StyledCol>
              <FieldLabel level={3} spaceBottom="XS">
                Phrase {phraseDisplayIndex}
              </FieldLabel>
              <TranslationsTipWrapper<RoleplayScenarioFieldWithLocalizationNames, RoleplayScenarioFormikValues>
                content={phraseContent}
                fieldName={phraseFieldName}
                errors={phraseBookPhraseErrors}
                interfaceLanguages={interfaceLanguages}
                type={ContentTypes.roleplayScenario}
                onContentSuggestionsClick={onContentSuggestionsClick}
                onOpenTranslationsPanel={onOpenTranslationPanel}
                onRemoveString={onRemoveString}
              >
                <WritableInputText
                  charsLimit={PHRASEBOOK_PHRASE_MAX_CHARS}
                  fontSize="16"
                  id={`scenario-phrasebook-phrase-${index}`}
                  placeholder=""
                />
              </TranslationsTipWrapper>
            </StyledCol>
          </StyledRow>
        );
      })}
    </>
  );
};
